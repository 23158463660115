import { NavLink } from "react-router-dom";
import { useState } from "react";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Navb.css";

function NavbFooter(props) {
  const idioma = Number(props.idioma);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="col-12">
          <hr/>
            <div className="mb-4 pt-2">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'black', fontSize: '1.2rem', cursor: 'pointer'}}>
                <span>{idioma &&
                ((idioma === 1 && "Inicio") ||
                  (idioma === 2 && "Start") ||
                  (idioma === 3 && "Começar"))}</span><BsIcons.BsChevronRight />
              </div>
            </div><hr/>
            <div className="mb-4 pt-2">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'black', fontSize: '1.2rem', cursor: 'pointer'}}>
                <span> {idioma &&
                ((idioma === 1 && "Servicios") ||
                  (idioma === 2 && "Services") ||
                  (idioma === 3 && "Serviços"))}</span><BsIcons.BsChevronRight />
              </div>
            </div><hr/>
            <div className="mb-4 pt-2">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'black', fontSize: '1.2rem', cursor: 'pointer'}}>
                <span>{idioma &&
                ((idioma === 1 && "Nosotros") ||
                  (idioma === 2 && "Us") ||
                  (idioma === 3 && "Nós"))}</span><BsIcons.BsChevronRight />
              </div>
            </div><hr/>
            <div className="mb-4 pt-2">
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'black', fontSize: '1.2rem', cursor: 'pointer'}}>
                <span> {idioma &&
                ((idioma === 1 && "Contacto") ||
                  (idioma === 2 && "Contact") ||
                  (idioma === 3 && "Contato"))}</span><BsIcons.BsChevronRight />
              </div>
            </div><hr/>
          </div>
        
        </Offcanvas.Body>
      </Offcanvas>
      <div id="footer-bar" className="footer-bar footer-bar-detached">
        <NavLink exact to="/">
          <AiIcons.AiOutlineHome className="font-17" />
          <span>
            {idioma &&
              ((idioma === 1 && "Inicio") ||
                (idioma === 2 && "Start") ||
                (idioma === 3 && "Começar"))}
          </span>
        </NavLink>
        <NavLink exact to="/">
          <AiIcons.AiOutlineShop className="font-17" />
          <span>
            {idioma &&
              ((idioma === 1 && "Servicios") ||
                (idioma === 2 && "Services") ||
                (idioma === 3 && "Serviços"))}
          </span>
        </NavLink>
        <NavLink exact to="/">
          <BsIcons.BsEnvelope className="font-17" />
          <span>
            {idioma &&
              ((idioma === 1 && "Contacto") ||
                (idioma === 2 && "Contact") ||
                (idioma === 3 && "Contato"))}
          </span>
        </NavLink>
        <div onClick={handleShow}>
          <BsIcons.BsGrid className="font-17" />
          <span>
            {idioma &&
              ((idioma === 1 && "Menú") ||
                (idioma === 2 && "Menu") ||
                (idioma === 3 && "Cardápio"))}
          </span>
        </div>
      </div>
    </>
  );
}

export default NavbFooter;
