import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { url } from "../../service/EndPoint";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";
import logo from "../../assets/image/logo.png"
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "./swiper.css";
import TypingText from "../../components/TypingText/TypingText";
import SliderApp from "../../components/SliderApp/SliderApp";

import NavbFooter from "../../components/NavFooter";
import * as BsIcons from "react-icons/bs";

export default function Home(props) {
  const idioma = Number(props.idioma);

  const [category, setCategory] = useState([]);
  const [galeria, setGaleria] = useState([]);
  const [formData, setFormData] = useState({
    direccion: "",
    telefono: "",
    telefonoWhatsapp: "",
    textoAnimado: "",
    quienes: "",
    cuit: "",
    razonSocial: "",
    condicion: "",
  });
  const numero = formData.telefonoWhatsapp;
  useEffect(() => {
    axios.post(url + "identidad/datos.php").then((getData) => {
      setFormData(getData.data[0]);
      localStorage.setItem("data", JSON.stringify(getData.data[0]));
    });
    axios.post(url + "category/list/list.php").then((getData) => {
      setCategory(getData.data);
    });

    axios.post(url + "galeria/list/list.php").then((getData) => {
      setGaleria(getData.data);
    });
  }, []);
  const categories =
    category &&
    category.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <NavLink exact to={"/servicios/" + item.id}>
            <div
              className="card"
              style={{ width: "100%", border: "0px", color: "black" }}
            >
              <img
                src={url + "category/image/" + item.urlImage}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <div className="cardItem">
                  <div>
                    <h5 className="">{idioma &&
                              ((idioma === 1 && item.nombre) ||
                                (idioma === 2 && item.nombreUS) ||
                                (idioma === 3 && item.nombrePT))}</h5>
                  </div>
                  <div style={{ color: "#eb3535" }}>
                    <BsIcons.BsHeart />
                  </div>
                </div>
                <div>
                  <p
                    className="card-text text-start text-muted"
                    style={{ fontSize: "1rem" }}
                  >
                    {idioma &&
                              ((idioma === 1 && item.descripcion.slice(0, 100) + "...") ||
                                (idioma === 2 && item.descripcionUS.slice(0, 100) + "...") ||
                                (idioma === 3 && item.descripcionPT.slice(0, 100) + "..."))}
                    
                  </p>
                </div>

                <div className="cardItem">
                  <div style={{ color: "#cd9e0f" }}>            {idioma &&
                              ((idioma === 1 && "Reservar") ||
                                (idioma === 2 && "Reserve") ||
                                (idioma === 3 && "Reserva"))}</div>
                  <div style={{ color: "#292740 " }}>
                  {idioma &&
                              ((idioma === 1 && "Detalles") ||
                                (idioma === 2 && "Details") ||
                                (idioma === 3 && "Detalhes"))}
                    </div>
                </div>
              </div>
            </div>
          </NavLink>
        </SwiperSlide>
      );
    });

  const [solicitud, setSolicitud] = useState({
    nombre: "",
    mail: "",
    consulta: "",
  });
  const onChangeValue = (e) => {
    setSolicitud({
      ...solicitud,
      [e.target.name]: e.target.value,
    });
  };
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function validarCorreoElectronico(correo) {
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  }

  function enviar() {
    setActivar(false);
    if (
      solicitud.nombre === "" ||
      solicitud.consulta === "" ||
      solicitud.mail === "" ||
      !validarCorreoElectronico(solicitud.mail)
    ) {
      setActivar(true);
      alert(
        "Por favor, debes completar los campos y ingresar un correo valido"
      );
    } else {
      
      axios
        .post(url + `solicitudes/consulta.php`, solicitud)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {});
    }
  }
  const texto = formData.textoAnimado;
  return (
    <>
      <div className="">
        <header id="load">
          <div id="layoutDefault">
            <div id="layoutDefault_content">
              <nav
                className="navbar navbar-expand-lg navbar-dark  fixed-top d-none d-sm-none d-md-block"
                style={{ background: "#292740", color: "#fff !important" }}
              >
                <div className="container-fluid">
                  <a
                    className="navbar-brand d-none d-sm-none d-md-block"
                    href="#"
                  >
                    <img src={logo} alt="logo" width={60} style={{borderRadius:'50%'}}/>
                    <span className="nenu-item">MDZ TRIP TOUR</span>
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNav"
                  >
                    <div className="menu ">
                      <div className="menu menu-titulo">
                        <a href="#servicios">
                          <div className="nenu-item">
                            {idioma &&
                              ((idioma === 1 && "SERVICIOS") ||
                                (idioma === 2 && "SERVICES") ||
                                (idioma === 3 && "SERVIÇO"))}
                          </div>
                        </a>
                        <NavLink exact to={"/quienes-somos"}>
                          <div className="nenu-item">
                            {idioma &&
                              ((idioma === 1 && "NOSOTROS") ||
                                (idioma === 2 && "US") ||
                                (idioma === 3 && "NÓS"))}
                          </div>
                        </NavLink>
                        <a href="#contacto">
                          <div className="nenu-item">
                            {idioma &&
                              ((idioma === 1 && "CONTACTO") ||
                                (idioma === 2 && "CONTACT") ||
                                (idioma === 3 && "CONTATO"))}
                          </div>
                        </a>
                        <div className="nenu-item">
                          <select
                            class="form-select"
                            aria-label="Español ES"
                            onChange={(e) =>
                              props.cambiarIdioma(e.target.value)
                            }
                          >
                            <option value={idioma && idioma}>
                              {idioma &&
                                ((idioma === 1 && "Español (ES)") ||
                                  (idioma === 2 && "English (US)") ||
                                  (idioma === 3 && "Português (PT)"))}
                            </option>
                            <option value="1">Español (ES)</option>
                            <option value="2">English (US)</option>
                            <option value="3">Portuguès (PT)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="d-sm-block d-md-none">
              
                          <select
                            class="form-select"
                            aria-label="Español ES"
                            onChange={(e) =>
                              props.cambiarIdioma(e.target.value)
                            }
                          >
                            <option value={idioma && idioma}>
                              {idioma &&
                                ((idioma === 1 && "Español (ES)") ||
                                  (idioma === 2 && "English (US)") ||
                                  (idioma === 3 && "Português (PT)"))}
                            </option>
                            <option value="1">Español (ES)</option>
                            <option value="2">English (US)</option>
                            <option value="3">Portuguès (PT)</option>
                          </select>
                        </div>
            </div>
          </div>
        </header>

        <div className="btnCenter">
          <div className="d-none d-sm-none d-md-block text-end">
            <a
              href={`https://api.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información&app_absent=0`}
              target="_blank"
            >
              <img
                src="https://cronogram.com.ar/assets/logo-wasap.png"
                className="shadow"
                style={{ borderRadius: "50px 50px" }}
                width={80}
                height={80}
              />
            </a>
          </div>
          <div className="d-sm-block d-md-none text-end">
            <a
              href={`https://api.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información&app_absent=0`}
              target="_blank"
            >
              <img
                src="https://cronogram.com.ar/assets/logo-wasap.png"
                className="shadow"
                style={{ borderRadius: "50px 50px" }}
                width={55}
                height={55}
              />
            </a>
          </div>
        </div>

        <div className="row">
          <SliderApp idioma={idioma}/>
          <div className="col-md-7 col-sm-12">
            <div className="container">
              <br className="d-none d-sm-none d-md-block" />
              <br className="d-none d-sm-none d-md-block" />
              <div className="titulo-1 text-center">
              {idioma &&
                  ((idioma === 1 && "A DONDE VAS ?") ||
                    (idioma === 2 && "WHERE ARE YOU GOING ?") ||
                    (idioma === 3 && "ONDE VOCÊ ESTÁ INDO ?"))}
                <br />
                {idioma &&
                  ((idioma === 1 && "NOSOTROS TE LLEVAMOS") ||
                    (idioma === 2 && "WE TAKE YOU") ||
                    (idioma === 3 && "NÓS LEVAMOS VOCÊ"))}
              </div>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <div className="box">
              <div className="text-center">
                <span className="titulo">
                  <TypingText
                    data={formData.textoAnimado}
                    idioma={props.idioma}
                    style={{ color: "black" }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <section id="servicios" className="py-5 bg-light">
          <br className="d-none d-sm-none d-md-block" />
          <br className="d-none d-sm-none d-md-block" />
          <div className="row mb-3">
            <div className="col-md-8 col-sm-12 mx-auto">
              <div className="container ">
                <div className="titulo-1 text-start">
                  {idioma &&
                    ((idioma === 1 && "Tours y traslados") ||
                      (idioma === 2 && "Tours and transfers") ||
                      (idioma === 3 && "Passeios e transferências"))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-sm-none d-md-block">
            <Swiper slidesPerView={3} spaceBetween={30} className="mySwiper">
              {categories}
            </Swiper>
          </div>
          <div className="d-sm-block d-md-none">
            <Swiper slidesPerView={2} spaceBetween={30} className="mySwiper">
              {categories}
            </Swiper>
          </div>

          <div></div>
        </section>
        <section style={{ background: "#bfd7dd" }}>
          <div className="row pt-5 pb-5">
            <div className="col-md-8 col-sm-12 mx-auto">
              <div className="container ">
                <div className="titulo-1 text-center">
                  {idioma &&
                    ((idioma === 1 && "MENDOZA TIERRA DEL BUEN VINO") ||
                      (idioma === 2 && "MENDOZA LAND OF GOOD WINE") ||
                      (idioma === 3 && "MENDOZA TIERRA DEL BUEN VINO"))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "0px !important",
          }}
        >
          {galeria &&
            galeria.map((data, index) => (
              <div className="col-md-4 col-sm-12" key={index}>
                <img
                  src={url + "/galeria/image/" + data.urlImage}
                  className="img-fluid"
                />
              </div>
            ))}
        </div>
        <section id="convenios" className="py-5 bg-light">
          <div className="row pt-5">
            <div className="col-12">
              <div className="box-1">
                <div className="text-center"> </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </section>

        <section id="contacto" style={{ background: "#bfd7dd" }}>
          <br className="d-none d-sm-none d-md-block" />
          <br className="d-none d-sm-none d-md-block" />
          <div className="row pt-3">
            <div className="col-12">
              <div className="container mb-5">
                <div className="titulo-1">
                  {idioma &&
                    ((idioma === 1 && "Alguna duda? Envianos un mensaje") ||
                      (idioma === 2 && "Any questions? Send us a message") ||
                      (idioma === 3 &&
                        "Alguma duvida? Envie-nos uma mensagem"))}
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header style={{ color: "grey" }}>
                      {idioma &&
                        ((idioma === 1 && "Consultar") ||
                          (idioma === 2 && "Find us") ||
                          (idioma === 3 && "Consultar"))}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        {exito ? (
                          <form>
                            <div className="row">
                              <div className="row g-2">
                                <div className="col-md">
                                  <div className="form-floating">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nombre"
                                      placeholder="NOMBRE"
                                      onChange={onChangeValue}
                                    />
                                    <label htmlFor="Nombre">
                                      {idioma &&
                                        ((idioma === 1 && "NOMBRE") ||
                                          (idioma === 2 && "NAME") ||
                                          (idioma === 3 && "NOME"))}
                                    </label>
                                    <div className="invalid-feedback">
                                      Por favor completa este campo
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="row g-2">
                                <div className="form-floating">
                                  <input
                                    type="mail"
                                    className="form-control"
                                    name="mail"
                                    placeholder="E.MAIL"
                                    onChange={onChangeValue}
                                  />
                                  <label htmlFor="mail">E.MAIL</label>
                                  <div className="invalid-feedback">
                                    Por favor completa este campo
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="row g-2">
                                <div className="form-floating">
                                  <textarea
                                    className="form-control"
                                    name="consulta"
                                    onChange={onChangeValue}
                                    id="floatingTextarea"
                                    defaultValue={""}
                                    style={{ height: "100px" }}
                                  />
                                  <label htmlFor="floatingTextarea">
                                    {idioma &&
                                      ((idioma === 1 && "Consulta") ||
                                        (idioma === 2 && "Consultation") ||
                                        (idioma === 3 && "Consulta"))}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="text-center pt-3">
                              <button
                                className="btn app-button text-white"
                                type="button"
                                onClick={() => activar && enviar()}
                              >
                                {idioma &&
                                  ((idioma === 1 && "Enviar") ||
                                    (idioma === 2 && "Send") ||
                                    (idioma === 3 && "Enviar"))}
                              </button>
                            </div>
                          </form>
                        ) : (
                          <div className="text-center">
                            {idioma &&
                              ((idioma === 1 && "Enviamos tu consulta !") ||
                                (idioma === 2 && "We send your query") ||
                                (idioma === 3 && "Enviamos sua consulta"))}
                          </div>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div
            className="container text-start mb-5 "
            style={{ color: "#292740" }}
          >
            <h3>
              <strong>
                {" "}
                {idioma &&
                  ((idioma === 1 && "Encontranos") ||
                    (idioma === 2 && "Find us") ||
                    (idioma === 3 && "Ache-nos"))}
              </strong>
            </h3>
            <br />
            {formData && formData.direccion}
            <br />
            {formData && formData.horario}
          </div>
          <div id="googlemaps" className="h-100">
            <iframe
              width="100%"
              height="400"
              src="https://maps.google.com/maps?q=Paseo%20Sarmiento%20N%C2%B0127%20Oficina%2011&t=&z=15&ie=UTF8&iwloc=&output=embed"
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </section>
        <Footer data={formData} idioma={idioma} />
        <div className="d-md-none d-sm-block">
          <NavbFooter idioma={idioma}/>
        </div>
      </div>
    </>
  );
}
