import { Redirect } from "react-router-dom";
import useAuth from "../auth/useAuth";
import { useState } from "react";
export default function PublicRoute({ component: Component, ...rest }) {
  const { rootState } = useAuth();
  const { isAuth, showLogin } = rootState;
  const [idioma,setIdioma] = useState(1)
  
  function cambiarIdioma(e){
        setIdioma(e)
  }
  if (isAuth) {
    return (
         <Redirect to="/dashboard" />    
    );
  } else if (showLogin) {
    return <Component idioma={idioma} cambiarIdioma={cambiarIdioma}/>;
  }
}
