import React, { useEffect, useState } from 'react';
import Typed from 'typed.js';

function TypingText(props) {
  const idiomas = Number(props.idioma);
  const [idioma, setIdioma] = useState(idiomas);
  const texto =
    idioma &&
    ((idioma === 1 && 'La mejor experiencia de viaje') ||
      (idioma === 2 && 'The best travel experience') ||
      (idioma === 3 && 'A melhor experiência de viagem'));

  useEffect(() => {
    // Configura las opciones de Typed.js
    const options = {
      strings: [texto],
      typeSpeed: 60,
      backSpeed: 30,
      loop: true,
      showCursor: false,
    };

    // Inicializa Typed.js en el elemento
    const typed = new Typed('.typing-text', options);

    // Limpia Typed.js cuando el componente se desmonta
    return () => {
      typed.destroy();
    };
  }, [idioma, texto]); // Añade idioma y texto como dependencias

  // Actualiza idioma cuando cambian las props
  useEffect(() => {
    setIdioma(idiomas);
  }, [idiomas]);

  return <div className="typing-text titulo"></div>;
}

export default TypingText;

