import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';

import { url } from "../../service/EndPoint";
import axios from "axios";

const PreguntasFrecuentes = (props) => {
  const idioma = Number(props.idioma);
	const [data, setData] = useState([])

	function getData(){
		axios
		.get(url + "preguntas/list/categoriasActivas.php")
		.then((getData) => {
		  setData(getData.data);
		});
	}
	useEffect(()=>{
		getData()
	},[])

  return (
    <div className="quienes">
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a
                  className="navbar-brand"
                  href="/home"
                >
                   <span className="nenu-item">MDZ TRIP TOUR</span>
                </a>

                  <div className="menu ">
                    <div className="menu menu-titulo">
                      <NavLink exact to={"/home"}>
                        <div className="nenu-item"> {idioma &&
                          ((idioma === 1 && "VOLVER") ||
                            (idioma === 2 && "RETURN") ||
                            (idioma === 3 && "RETORNAR"))}</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
             
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5">
			<div className="pt-5 mb-3">
			<div className="titulo-1 ">                
                {idioma &&
                          ((idioma === 1 && "PREGUNTAS FRECUENTES") ||
                            (idioma === 2 && "FREQUENT QUESTIONS") ||
                            (idioma === 3 && "PERGUNTAS FREQUENTES"))}
              </div>
			</div>
			<div className="pt-3">
				{data.length > 0 ?
				<Accordion>
					{data && data.map((a,index)=>(
					<Accordion.Item eventKey={index} key={index}>
						<Accordion.Header>
            {idioma &&
                          ((idioma === 1 && a.nombre) ||
                            (idioma === 2 && a.nombreUS) ||
                            (idioma === 3 && a.nombrePT))}
            </Accordion.Header>
						<Accordion.Body>
		              {idioma &&
                          ((idioma === 1 && a.descripcion) ||
                            (idioma === 2 && a.descripcionUS) ||
                            (idioma === 3 && a.descripcionPT))}
						</Accordion.Body>
				</Accordion.Item>
					))}				

    			</Accordion>
				:
				<div className="container">
					No hay preguntas en este momento.
				</div>
				}
				</div>           
      </div>



      </div>

	</div>
  );
};

export default PreguntasFrecuentes;