import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/login/Login";
import Restore from "../pages/restore/Restore";
import Home from "../pages/home/Home";
import NotFoundPage from "../pages/404/NotFoundPage";
import Dashboard from "../pages/Dashboard/Dashboard"
import Quienes from "../pages/Quienes/Quienes";
import UsersProfile from "../pages/profile/usersProfile";
import Notificaciones from "../pages/Notificaciones/Notificaciones";
import { ListaConsultas } from "../pages/ListaConsultas";
import Category from "../pages/category/Category/Category";
import Servicios from "../pages/category/Servicios/Servicios";
import { ConveniosCategoria } from "../pages/Convenios/Convenios";
import Entidad from "../pages/Convenios/Entidad/Entidad";
import Preguntas from "../pages/Preguntas/Preguntas";
import PreguntasFrecuentes from "../pages/PreguntasFrecuentes/PreguntasFrecuentes";
import ConveniosLista from "../pages/ConveniosLista/ConveniosLista";
import ListasServicios from "../pages/ListasServicios/ListasServicios";
import MasInformacion from "../pages/MasInformacion/MasInformacion";
import Identidad from "../pages/Identidad/Identidad";
import { Galeria } from "../pages/Galeria/Galeria";
import ListaReservas from "../pages/ListaReservas/ListaReservas";
import ListaComentarios from "../pages/ListaComentarios/ListaComentarios";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/restore" component={Restore} />
          <PublicRoute exact path="/quienes-somos" component={Quienes} />
        <PublicRoute exact path="/home" component={Home} />
        <PublicRoute exact path="/mas-informacion" component={MasInformacion} />
        <PublicRoute exact path="/convenios/:id" component={ConveniosLista} />
        <PublicRoute exact path="/servicios/:id" component={ListasServicios} />
        <PublicRoute exact path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/galeria-imagenes" component={Galeria} />
        <PrivateRoute exact path="/usuarios" component={UsersProfile} />
        <PrivateRoute exact path="/notificaciones" component={Notificaciones} />
        <PrivateRoute exact path="/consultas" component={ListaConsultas} />
        <PrivateRoute exact path="/reservas" component={ListaReservas} />
        <PrivateRoute exact path="/comentarios" component={ListaComentarios} />
        <PrivateRoute exact path="/servicios-categoria" component={Category} />
        <PrivateRoute exact path="/servicios-categoria/servicios/:id" component={Servicios} />
        <PrivateRoute exact path="/convenios-categoria" component={ConveniosCategoria} />
        <PrivateRoute exact path="/convenios-categoria/servicios/:id" component={Entidad} />
        <PrivateRoute exact path="/preguntas" component={Preguntas} />
        <PrivateRoute exact path="/identidad-sitio" component={Identidad} />
        <Route path="/404" component={NotFoundPage} />        
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Router>
  );
}
