import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { url } from "../../service/EndPoint";
import "./Notificaciones.css";

const Notificaciones = ({}) => {
  const [consultas, setConsultas] = useState([]);
  const [reservas, setReservas] = useState([]);

  useEffect(() => {
    axios
      .post(url + `solicitudes/all.php`)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          if (data.success === 1) {
            setConsultas(data.consultas);
            setReservas(data.reservas)
          }
        }
      })
      .catch(function (res) {});
  }, []);

  return (
    <div className="notificaciones">
      <div className="container pt-3">
      <div className="text-start">
        <NavLink exact to={"/dashboard"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
        <div
          className="card shadow mb-3"
          style={{ background: "#292740", border: "0px" }}
        >
          <div className="card-body">
            <div className="text-center text-white">Notificaciones</div>
          </div>
        </div>
        <div className="row pt-5">


          <div className="col-md-6 col-sm-12  mb-3">
            <NavLink exact to={"/consultas"}>
              <div className="card shadow ">
                <div className="contend-card position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill "
                    style={{
                      backgroundColor:
                        consultas.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {consultas && consultas.length}
                  </span>
                  <div className="container">
                    <div className="text-start">
                      <span style={{ color: "#474747" }}>Consultas</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-6 col-sm-12  mb-3">
            <NavLink exact to={"/reservas"}>
              <div className="card shadow ">
                <div className="contend-card position-relative">
                  <span
                    className="position-absolute top-0 start-100 translate-middle badge rounded-pill "
                    style={{
                      backgroundColor:
                        reservas.length === 0 ? "#6c757d" : "#dc3545",
                    }}
                  >
                    {reservas && reservas.length}
                  </span>
                  <div className="container">
                    <div className="text-start">
                      <span style={{ color: "#474747" }}>Reserva</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notificaciones;
