import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import moment from 'moment';

import { url } from "../../service/EndPoint";



const Resenas = (props) => {
	const item = props.item[0] ? props.item[0] : null
	const colores = [ '#ffc700', '#3e96fe','#eb3f69','#50cd89','#736ced'];
	const [data, setData] = useState([]);
	const [registro, setRegistro]= useState('')
	const [enviado, setEnviado]= useState(true)
	useEffect(() => {
		if(item){
			
		axios
		  .get(url + "resenas/list/list.php?id="+item.id)
		  .then((getData) => {
			setData(getData.data);
			
		  });
		}
	  }, [item]);
	  function getData() {
		axios
		  .get(url + "resenas/list/list.php?id="+item.id)
		  .then((getData) => {
			setData(getData.data);
			
		  });
	  }
	  function handleSubmit(e) {
		e.preventDefault();
	  
		// Validar campos
		if (!registro.nombre || !registro.descripcion || !registro.email) {
		  // Manejar el caso en que algún campo esté vacío
		  window.alert('Por favor, complete todos los campos');
		  return;
		}
	  
		// Puedes agregar más validaciones según tus requisitos, por ejemplo, validar el formato del correo electrónico
	  
		const datas = new FormData();      
		datas.append("nombre", registro.nombre);
		datas.append("descripcion", registro.descripcion);
		datas.append("email", registro.email);
		datas.append("id_servicio", Number(item.id));
	  
		axios.post(url + `resenas/add/index.php`, datas, {
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		})
		.then((res) => {
		  let { data } = res;
		  if (Number(data.success) === 1) {
			setRegistro('null')
			getData();
			setEnviado(false)       
		  }
		})
		.catch(function (error) {
		  // Manejar errores de la solicitud
		  console.error('Error al enviar la solicitud:', error);
		});
	  }
	  
	  const onChangeValue = (e) => {
		setRegistro({
		  ...registro,
		  [e.target.name]: e.target.value,
		});
	  };

	return (
		<div>
			{data && <div className="mb-3"><strong>Comentarios</strong></div>}
 			{data.length > 0 ? data.map((x,index)=>(
				<div className="row mb-3" key={index} style={{background:'#ededed', padding: '10px'}}>
					<div className="col-md-1 col-sm-1">
						<div><small>{moment(x.create_at).format("DD/MM/YYYY HH:mm")}</small></div>
					</div>
					<div className="col-md-11 col-sm-11">
					<div className="text-start">
						
					<div className="symbol symbol-35px symbol-circle" >
                      <div style={{width:'35px', height: '35px',background: colores[index % colores.length],color:'white',borderRadius:'50%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {x.nombre.substr(0,2)} 
                        </div> 
                      </div>{' '}
						
						
						<span>{x.nombre}</span>
					</div>
					<div className="text-start text-muted pt-2">
						{x.descripcion}
					</div>
					{(x.respuesta).length > 0 &&					
					<div className="text-end  pt-2">
						{x.respuesta}<br/>
						<small className="text-muted">MDZ TRIP TOUR</small>
					</div>
					}
					</div>
				</div>
			))
		:
		<div className="pt-3">
		<small className="text-muted">Sin comentarios</small>
		</div>
		}
		{enviado &&

	
	 <form onSubmit={handleSubmit}>
			<div className="row pt-5"><hr/>
				<div className="mb-3">
					<strong>Comentar</strong>
				</div>			
				<div className="col-md-6 col-sm-12 mb-3">
					<input className="form-control" name="nombre" placeholder="Nombre" required onChange={onChangeValue}/>
				</div>
				<div className="col-md-6 col-sm-12 mb-3">
					<input className="form-control" name="email" placeholder="Email" required  onChange={onChangeValue}/>
				</div>
				<div className="col-sm-12">
					<div className="input-group">						
						<textarea className="form-control" name="descripcion" aria-label="Comentario" required onChange={onChangeValue}></textarea>
						<span className="input-group-text"><BsIcons.BsFillSendFill onClick={handleSubmit}/></span>
					</div>					
				</div>			
			</div>
			</form>
}
 		</div>
	);
};



export default Resenas;