import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { url } from "../../service/EndPoint";
import axios from "axios";
import "./ListasServicios.css";
import Accordion from "react-bootstrap/Accordion";
import Resenas from "../../components/Resenas/Resenas";

const ListasServicios = (props) => {
  const idioma = Number(props.idioma);
  const idUrl = window.location.href;
  const id = idUrl.split("/").pop();
  const [data, setData] = useState([]);
  const [preguntas, setPreguntas] = useState([]);

  function getData() {
    axios
      .get(url + "category/list/itemhome.php?categoria=" + id)
      .then((getData) => {
        setData(getData.data);
      });

    axios.get(url + "preguntas/list/categoriasActivas.php").then((getData) => {
      setPreguntas(getData.data);
    });
  }
  useEffect(() => {
    getData();
  }, []);

  const [solicitud, setSolicitud] = useState({
    origen:'',
    destino:'',
    fecha:'',
    hora:'',
    minutos:'',
    personas:'',
    telefono:''
  });
  const onChangeValue = (e) => {
    setSolicitud({
      ...solicitud,
      [e.target.name]: e.target.value,
    });
  };
  const [activar, setActivar] = useState(true);
  const [exito, setExito] = useState(true);

  function validarCorreoElectronico(correo) {
    var expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  }

  function enviar() {
    setActivar(false);
    if (
      solicitud.origen === "" ||
      solicitud.destino === "" ||      
      solicitud.fecha === "" ||
      solicitud.hora === "" ||
      solicitud.minutos === "" ||
      solicitud.personas === "" ||
      solicitud.telefono === ""      
    ) {
      setActivar(true);
      alert("Por favor, debes completar los campos ");
    } else {
      
      axios
        .post(url + `solicitudes/reserva.php`, solicitud)
        .then((res) => {
          let { data } = res;
          if (data.success && data.message) {
            if (data.success === 1) {
              setExito(false);
            }
          }
        })
        .catch(function (res) {
          console.log(res)
        });
    }
  }
  const mendoza = [
    "Capital",
    "Godoy Cruz",
    "Guaymallén",
    "Junín",
    "La Paz",
    "Las Heras",
    "Lavalle",
    "Luján de Cuyo",
    "Maipú",
    "Malargüe",
    "Rivadavia",
    "San Carlos",
    "San Martín",
    "San Rafael",
    "Santa Rosa",
    "Tunuyán",
    "Tupungato",
  ];

  return (
    <div className="convenioslista">
      <div className="btnCenter">
        <div className="d-none d-sm-none d-md-block text-end">
          <a
            href={`https://api.whatsapp.com/send/?phone=%2B5492612791241&text=Buen+día+quiero+información&app_absent=0`}
            target="_blank"
          >
            <img
              src="https://cronogram.com.ar/assets/logo-wasap.png"
              className="shadow"
              style={{ borderRadius: "50px 50px" }}
              width={80}
              height={80}
            />
          </a>
        </div>
        <div className="d-sm-block d-md-none text-end">
          <a
            href={`https://api.whatsapp.com/send/?phone=%2B5492612791241&text=Buen+día+quiero+información&app_absent=0`}
            target="_blank"
          >
            <img
              src="https://cronogram.com.ar/assets/logo-wasap.png"
              className="shadow"
              style={{ borderRadius: "50px 50px" }}
              width={55}
              height={55}
            />
          </a>
        </div>
      </div>
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <span className="nenu-item">MDZ TRIP TOUR</span>
                </a>

                <div className="menu ">
                  <div className="menu menu-titulo">
                    <NavLink exact to={"/home"}>
                      <div className="nenu-item">
                        {idioma &&
                          ((idioma === 1 && "VOLVER") ||
                            (idioma === 2 && "RETURN") ||
                            (idioma === 3 && "RETORNAR"))}
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5 pb-5">
          <div className="pt-3">
            {data.length > 0 ? (
              <div>
                {data &&
                  data.map((a, index) => (
                    <div className="row" key={index}>
                      <div className="col-md-6 col-sm-12 pt-3">
                        <img
                          src={url + "category/image/" + a.urlImage}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="titulo-1 ">{idioma &&
                              ((idioma === 1 && a.nombre) ||
                                (idioma === 2 && a.nombreUS) ||
                                (idioma === 3 && a.nombrePT))}</div>
                        <div>{idioma &&
                              ((idioma === 1 && a.descripcion) ||
                                (idioma === 2 && a.descripcionUS) ||
                                (idioma === 3 && a.descripcionPT))}</div>
                      </div>
                    </div>
                  ))}
                {exito ? (
                  <form>
                    <div className="pt-5">
                      <div className="titulo-1 ">{idioma &&
                          ((idioma === 1 && "Reservar") ||
                            (idioma === 2 && "Reserve") ||
                            (idioma === 3 && "Reserva"))}</div>
                    </div>
                    <div className="row">
                      <div className="row g-2">
                        <div className="col-md">
                          <div className="form-floating">
                            <select
                              className="form-control"
                              id="origen"
                              name="origen"
                              placeholder={idioma &&
                                ((idioma === 1 && "Origen Localidad") ||
                                  (idioma === 2 && "Origin Localition") ||
                                  (idioma === 3 && "Localização de Origem"))}
                              onChange={onChangeValue}
                            >
                              <option></option>
                              {mendoza &&
                                mendoza.map((departamento, index) => (
                                  <option key={index} value={departamento}>
                                    Localidad {departamento}
                                  </option>
                                ))}
                            </select>
                            <label htmlFor="origen">{idioma &&
                                ((idioma === 1 && "Origen Localidad") ||
                                  (idioma === 2 && "Origin Localition") ||
                                  (idioma === 3 && "Localização de Origem"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="destino"
                              placeholder={idioma &&
                                ((idioma === 1 && "Destino") ||
                                  (idioma === 2 && "Destination") ||
                                  (idioma === 3 && "Destino"))}
                              onChange={onChangeValue}
                            />
                            <label htmlFor="destino">{idioma &&
                                ((idioma === 1 && "Destino") ||
                                  (idioma === 2 && "Destination") ||
                                  (idioma === 3 && "Destino"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row g-2">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="date"
                              className="form-control"
                              name="fecha"
                              placeholder={idioma &&
                                ((idioma === 1 && "Fecha") ||
                                  (idioma === 2 && "Date") ||
                                  (idioma === 3 && "Data"))}
                              onChange={onChangeValue}
                            />
                            <label htmlFor="fecha">{idioma &&
                                ((idioma === 1 && "Fecha") ||
                                  (idioma === 2 && "Date") ||
                                  (idioma === 3 && "Data"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              name="hora"
                              placeholder={idioma &&
                                ((idioma === 1 && "Hora") ||
                                  (idioma === 2 && "Hour") ||
                                  (idioma === 3 && "Hora"))}
                              min="0"
                              max="24"
                              onChange={onChangeValue}
                            />
                            <label htmlFor="hora">{idioma &&
                                ((idioma === 1 && "Hora") ||
                                  (idioma === 2 && "Hour") ||
                                  (idioma === 3 && "Hora"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              name="minutos"
                              placeholder={idioma &&
                                ((idioma === 1 && "Minutos") ||
                                  (idioma === 2 && "Minutes") ||
                                  (idioma === 3 && "Minutos"))}
                              min="0"
                              max="60"
                              onChange={onChangeValue}
                            />
                            <label htmlFor="minutos">{idioma &&
                                ((idioma === 1 && "Minutos") ||
                                  (idioma === 2 && "Minutes") ||
                                  (idioma === 3 && "Minutos"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="row g-2">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="number"
                              className="form-control"
                              name="personas"
                              min="1"
                              max="4"
                              placeholder={idioma &&
                                ((idioma === 1 && "Personas") ||
                                  (idioma === 2 && "People") ||
                                  (idioma === 3 && "Pessoas"))}
                              onChange={onChangeValue}
                            />
                            <label htmlFor="personas">{idioma &&
                                ((idioma === 1 && "Personas") ||
                                  (idioma === 2 && "People") ||
                                  (idioma === 3 && "Pessoas"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              name="telefono"
                              placeholder={idioma &&
                                ((idioma === 1 && "Teléfono de contacto") ||
                                  (idioma === 2 && "Contact phone") ||
                                  (idioma === 3 && "Telefone de contato"))}
                              onChange={onChangeValue}
                            />
                            <label htmlFor="telefono">{idioma &&
                                ((idioma === 1 && "Teléfono de contacto") ||
                                  (idioma === 2 && "Contact phone") ||
                                  (idioma === 3 && "Telefone de contato"))}</label>
                            <div className="invalid-feedback">
                              Por favor completa este campo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center pt-5">
                      <button
                        className="btn app-button text-white"
                        type="button"
                        onClick={() => enviar()}
                      >
                        {idioma &&
                                ((idioma === 1 && "Reservar") ||
                                  (idioma === 2 && "Reserve") ||
                                  (idioma === 3 && "Reserva"))}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center pt-5">
                    <div className="pt-5 titulo-1">
                    {idioma &&
                                ((idioma === 1 && "Enviamos tu reserva en breve nos comunicaremos !") ||
                                  (idioma === 2 && "We send your reservation shortly we will contact you") ||
                                  (idioma === 3 && "Enviamos sua reserva em breve comunicaremos!"))}
                     
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="container"> {idioma &&
                ((idioma === 1 && "No hay servicios en este momento.") ||
                  (idioma === 2 && "There are no services at this time.") ||
                  (idioma === 3 && "RETORNAR"))}</div>
            )}
          </div>
        </div>
        <div className="pt-5 mb-5">
          <Resenas item={data} idioma={idioma}/>
        </div><hr/>
        <div className="mb-3">
					<strong>Preguntas frecuentes</strong>
				</div>	
        {preguntas.length > 0 && (
          <Accordion>
            {preguntas &&
              preguntas.map((a, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{idioma &&
                          ((idioma === 1 && a.nombre) ||
                            (idioma === 2 && a.nombreUS) ||
                            (idioma === 3 && a.nombrePT))}</Accordion.Header>
                  <Accordion.Body> {idioma &&
                          ((idioma === 1 && a.descripcion) ||
                            (idioma === 2 && a.descripcionUS) ||
                            (idioma === 3 && a.descripcionPT))}</Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default ListasServicios;
