import React from 'react';
import './Footer.css';
import * as BsIcons from "react-icons/bs";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import logo from "../../assets/image/logo.png"

const Footer = (props) => {
	const idioma = Number(props.idioma);
	const data =JSON.parse(localStorage.getItem('data'))
	return <div className='footer' style={{background:'#292740', color: '#fff'}}>
				<div className='container pb-5'>
					<div className='row pt-5 pb-5'>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3 text-center'>
									<img src={logo} alt="logo" width={60} style={{borderRadius:'50%'}}/><h1>MDZ TRIP TOUR</h1>
									<small>MENDOZA - ARGENTINA</small>
									</li>
												
								</ul>
							</div>
						</div>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> {idioma &&
                          ((idioma === 1 && "Dirección:") ||
                            (idioma === 2 && "Address:") ||
                            (idioma === 3 && "Endereço:"))} {data && data.direccion}
									</li>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> {idioma &&
                          ((idioma === 1 && "Teléfono:") ||
                            (idioma === 2 && "Phone:") ||
                            (idioma === 3 && "Telefone:"))} {data && data.telefono}
									</li>
									<li className='mb-3'>
									<BsIcons.BsChevronRight/> E-mail: {data && data.email}
									</li>
								</ul>
							</div>
						</div>
						<div className='col-md-4 col-sm-12'>
							<div>
								<ul>
									<li className='mb-3'>
										<NavLink to='/preguntas-frecuentes' style={{color: '#fff'}}>
											<BsIcons.BsChevronRight/> {idioma &&
                          ((idioma === 1 && "PREGUNTAS FRECUENTES") ||
                            (idioma === 2 && "FREQUENT QUESTIONS") ||
                            (idioma === 3 && "PERGUNTAS FREQUENTES"))}
										</NavLink>									
									</li>
			 					<li className='mb-3'>
										<NavLink to='/login' style={{color: '#fff'}}>
											<BsIcons.BsChevronRight/> {idioma &&
                          ((idioma === 1 && "INGRESAR") ||
                            (idioma === 2 && "GET INTO") ||
                            (idioma === 3 && "ENTRAR"))}
										</NavLink>									
									</li>
								</ul>
							</div>
						</div><br/>
					</div>
				</div>
			</div>;
};

Footer.propTypes = {};

export default Footer;