import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import img1 from "../../assets/image/24.jpg";
import img2 from "../../assets/image/25.jpg";
import logo from "../../assets/image/logo.png"

const SliderApp = (props) => {
	const idiomas = Number(props.idioma);
	const [idioma, setIdioma] = useState(idiomas);
	useEffect(() => {
		setIdioma(idiomas);
	  }, [idiomas]);
	return  (
		<>
		<div className='d-none d-sm-none d-md-block'>
			<Carousel>
				<Carousel.Item
				style={{position:'relative'}}>
					<img src={img1} className="d-block w-100 "  style={{height:'700px'}} alt="First slide" />
					<div className='text-center' style={{color: 'white', position:'absolute', top:'30%', left:'40%', fontSize:'3rem', }}>
						<img src={logo} width={150} style={{borderRadius:'50%'}}/><br/>
						<span>MDZ TRIP TOUR</span><br/>
						<span style={{fontSize:'2rem'}}>
						{idioma &&
                  ((idioma === 1 && "SEVICIO DE TRASLADO") ||
                    (idioma === 2 && "TRANSFER SERVICE") ||
                    (idioma === 3 && "SERVIÇO DE TRANSFERÊNCIA"))}
						</span><br/>
						<small style={{fontSize:'1rem'}}>MENDOZA - ARGENTINA</small>
					</div>
				<Carousel.Caption>
				
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item
				style={{position:'relative'}}
				>
					<img src={img2} className="d-block w-100" style={{height:'700px'}} alt="..." text="First slide"/>
					<div className='text-center' style={{color: 'white', position:'absolute', top:'30%', left:'40%', fontSize:'3rem', }}>
						<img src={logo} width={150} style={{borderRadius:'50%'}}/><br/>
						<span>MDZ TRIP TOUR</span><br/>
						<span style={{fontSize:'2rem'}}>
						{idioma &&
                  ((idioma === 1 && "SEVICIO DE TRASLADO") ||
                    (idioma === 2 && "TRANSFER SERVICE") ||
                    (idioma === 3 && "SERVIÇO DE TRANSFERÊNCIA"))}
						</span><br/>
						<small style={{fontSize:'1rem'}}>MENDOZA - ARGENTINA</small>
					</div>
				<Carousel.Caption>
				</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</div>
		<div className=' d-sm-block d-md-none'>
			<Carousel>
				<Carousel.Item style={{position:'relative'}}>
					<img src={img1} className="d-block w-100 "   alt="First slide"/>
					<div className='text-center' style={{color: 'white', position:'absolute', top:'20%', left:'35%', backgroundColor:'#ffffff47', padding:'5px', borderRadius:'5px'}}>
						<img src={logo} width={60} style={{borderRadius:'50%'}}/><br/>
						<span>MDZ TRIP TOUR</span><br/>
						<span style={{fontSize:'0.7rem'}}>
						{idioma &&
                  ((idioma === 1 && "SEVICIO DE TRASLADO") ||
                    (idioma === 2 && "TRANSFER SERVICE") ||
                    (idioma === 3 && "SERVIÇO DE TRANSFERÊNCIA"))}
						</span><br/>
						<small style={{fontSize:'0.6rem'}}>MENDOZA - ARGENTINA</small>
					</div>
				<Carousel.Caption>
				
				</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item style={{position:'relative'}}>
					<img src={img2} className="d-block w-100" alt="..." text="First slide"/>
					<div className='text-center' style={{color: 'white', position:'absolute', top:'20%', left:'35%', backgroundColor:'#ffffff47', padding:'5px', borderRadius:'5px'  }}>
						<img src={logo} width={60} style={{borderRadius:'50%'}}/><br/>
						<span>MDZ TRIP TOUR</span><br/>
						<span style={{fontSize:'0.7rem'}}>
						{idioma &&
                  ((idioma === 1 && "SEVICIO DE TRASLADO") ||
                    (idioma === 2 && "TRANSFER SERVICE") ||
                    (idioma === 3 && "SERVIÇO DE TRANSFERÊNCIA"))}
						</span><br/>
						<small style={{fontSize:'0.6rem'}}>MENDOZA - ARGENTINA</small>
					</div>
				<Carousel.Caption>
				</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</div>
</>
	)
};

SliderApp.propTypes = {};

export default SliderApp;