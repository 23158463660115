import "./ListaComentarios.css";
import React, {  useState, useEffect } from "react";
import { url } from "../../service/EndPoint";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import ComentariosModal from "../../components/ComentariosModal/ComentariosModal";

const ListaComentarios = ({}) => {
  const [filtro, setFiltro] = useState("");
  const [data, setData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [carga, setCarga] = useState(true);
  useEffect(() => {
    axios.get(url + "resenas/list/listAll.php").then((response) => {
      setData(response.data);
      
      setCarga(false);
    });
  }, [data]);
  function actualizar() {
    axios.get(url + "resenas/list/listAll.php").then((response) => {
      setData(response.data);
    });
  }
  function formatearFecha(fecha) {
    if (!(fecha instanceof Date)) {
      fecha = new Date(fecha);
    }

    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const año = fecha.getFullYear();

    const diaFormateado = dia.toString().padStart(2, "0");
    const mesFormateado = mes.toString().padStart(2, "0");

    const fechaFormateada = `${diaFormateado}/${mesFormateado}/${año}`;

    return fechaFormateada;
  }

  const handleShowModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };
  function onErase(id) {
    axios.get(url + `resenas/delete/delete.php?id=${id}`).then(() => {
      actualizar()
    });
  }

  return (
    <div className="ListaReservas">
      <div className="container mx-auto pt-5">
        <div className="row pt-3">
          <div className="col-1">
            <NavLink exact to={"/dashboard"}>
              <h1>
                <BsIcons.BsArrowLeftShort className="app-color display-1" />
              </h1>
            </NavLink>
          </div>
          <div className="col">
            <h1 className="titulo-1 pt-3">Lista de comentarios</h1>
          </div>
        </div>
        <div className="pt-5 mb-3">
          <div className="input-group ">
            <input
              placeholder="Buscar"
              className="form-control form-control-sm"
              type="text"
              onChange={(e) => setFiltro(e.target.value)}
            />
            <span className="input-group-text" id="basic-addon2">
              <BsIcons.BsSearch />
            </span>
          </div>
        </div>
        {carga ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>

            ) : (
              <>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre</th>
              <th scope="col">Servicio</th>
              <th scope="col">Fecha</th>
              <th scope="col">Estado</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>

                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <tr
                        onClick={() => handleShowModal(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <th scope="row">{index + 1}</th>
                        <td>{item.nombre}</td>
                        <td>{item.id_servicio}</td>
                        <td>{formatearFecha(item.create_at)}</td>
                        <td>
                          {Number(item && item.estado) === 0 && (
                            <span className="badge-danger">Nuevo</span>
                          )}
                          {Number(item && item.estado) === 1 && (
                            <span className="badge-warning">Pendiente</span>
                          )}{" "}
                          {Number(item && item.estado) === 2 && (
                            <span className="badge-success">Cerrada</span>
                          )}
                        </td>
                        <td><BsIcons.BsTrash onClick={()=>onErase(item.id)}/></td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No hay comentarios.</td>
                  </tr>
                )}
            
          </tbody>
        </table>
        </>
            )}
          <ComentariosModal show={showModal}
          onHide={handleCloseModal}
          data={selectedItem}
          actualizar={actualizar}/>
      </div>
    </div>
  );
};

export default ListaComentarios;
