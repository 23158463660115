import React from "react";
import "./Quienes.css";
import { NavLink } from "react-router-dom";

import Image2 from "../../assets/image/flota.jpg"
import Footer from "../../components/Footer/Footer";

const Quienes = (props) => {
  const idioma = Number(props.idioma);
  const data =JSON.parse(localStorage.getItem('data'))
  return (
    <div className="quienes">
      <header id="load">
        <div id="layoutDefault">
          <div id="layoutDefault_content">
            <nav
              className="navbar navbar-expand-lg navbar-dark  fixed-top"
              style={{ background: "#292740", color: "#fff !important" }}
            >
              <div className="container-fluid">
                <a
                  className="navbar-brand"
                  href="/home"
                >
                   <span className="nenu-item">MDZ TRIP TOUR</span>
                </a>

                  <div className="menu ">
                    <div className="menu menu-titulo">
                      <NavLink exact to={"/home"}>
                        <div className="nenu-item">
                               {idioma &&
                              ((idioma === 1 && "VOLVER") ||
                                (idioma === 2 && "RETURN") ||
                                (idioma === 3 && "RETORNAR"))}
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
             
            </nav>
          </div>
        </div>
      </header>
      <div className="container pt-5 pb-5">
        <div className="row pt-5">
			<div className="pt-3 mb-3">
      <div className="titulo-1 ">
      {idioma &&
                              ((idioma === 1 && "Quíenes somos") ||
                                (idioma === 2 && "About us") ||
                                (idioma === 3 && "Quem somos"))}
      </div>
			</div>           
          <div className="col-md-8 col-sm-12 text-start pt-3">
       
              {data && <div dangerouslySetInnerHTML={{ __html: data.quienes}}></div>}
      
          </div>
		  <div className="col-md-4 col-sm-12">
			<div className="container-image">
				<img src={Image2} className="img-fluid"/>
			</div>				
		  </div>
        </div>



      </div>
<div className=" bg-blue-sky-light ">
	<div className="container ">
	  <div className="row">
    <div className="col-md-6 col-sm-12 ">
		<div className="container pt-5 pb-5">
      <div className="overflow-hidden ">
        <h2
          className="font-weight-bold mb-4  text-color-blue-amuam "

        >
          Datos tributarios
        </h2>
      </div>
      <ul
        className="list list-unstyled  mb-0 "
     
      >
        <li className=" align-items-center text-2 pb-1 mb-2">
          <i className="fas fa-check text-color-blue-amuam text-2 mr-2" />
          <span className="text-color-blue-amuam font-weight-semibold">
            Razón social.
          </span>
          <p className="text-color-gray"> {data && data.razonSocial}</p>
        </li>
        <li className=" align-items-center text-2 pb-1 mb-2">
          <i className="fas fa-check text-color-blue-amuam text-2 mr-2" />
          <span className="text-color-blue-amuam font-weight-semibold">
            CUIT.
          </span>
          <p className="text-color-gray"> {data && data.cuit}</p>
        </li>
        <li className="align-items-center text-2 pb-1 mb-2">
          <i className="fas fa-check text-color-blue-amuam text-2 mr-2" />
          <span className="text-color-blue-amuam font-weight-semibold">
            Condición IVA.
          </span>
          <p className="text-color-gray"> {data && data.condicion}</p>
        </li>
      </ul>
	  </div>
    </div>
	<div className="col-md-6 col-sm-12 quiene-img align-self-stretch">
	
	</div>
  </div>
</div>
    </div>
	<Footer/>
	</div>
  );
};

export default Quienes;
